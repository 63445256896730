import * as React from "react"

type Lesson = {
    title: string
    summary: string
}

type Subgroup = {
    title: string
    lessons: Lesson[]
}

type CourseSection = {
    title: string
    description: string
    subgroups: Subgroup[]
}

const courseSections: CourseSection[] = [
    {
        title: "Start Here: The Crispy Contact System",
        description:
            "Your fast-track to better contact. Learn a swing drill you can use anywhere — no ball or range required.",
        subgroups: [
            {
                title: "Core Lessons",
                lessons: [
                    {
                        title: "Introduction (1:48)",
                        summary:
                            "Overview of the Crispy Contact System and how to use the program.",
                    },
                    {
                        title: "Build the Machine – The Setup (4:12)",
                        summary:
                            "Set up your grip, posture, arms, and weight to create a powerful, consistent swing foundation.",
                    },
                    {
                        title: "Swing the Machine – The Swing (4:19)",
                        summary:
                            "Learn the '2 Move Swing' an incredibly powerful and consistent motion that requires minimal thinking",
                    },
                    {
                        title: "Practice on the Range! (5:03)",
                        summary:
                            "Step-by-step practice routine to get you striking solid shots with irons, hybrids, and driver.",
                    },
                ],
            },
            {
                title: "BONUS CONTENT",
                lessons: [
                    {
                        title: "Diagnosing Problems and Quick Fixes (1:59)",
                        summary:
                            "Fast fixes for common contact issues like fat and thin shots (includes links to drills that fix these issues).",
                    },
                    {
                        title: "Realtime Practice Part 1 (27:34)",
                        summary:
                            "Improve 10x faster - Tom Saguto shows you how he practices The Crispy Contact system in real time.",
                    },
                    {
                        title: "Realtime Practice Part 2 (24:50)",
                        summary:
                            "Continuation of the live practice session including the driver.",
                    },
                ],
            },
        ],
    },
    {
        title: "Bonus Section: Amazing Ball-Striking Drills",
        description:
            "Targeted drills that fix slices, fat shots, steep swings, and more.",
        subgroups: [
            {
                title: "Drills",
                lessons: [
                    {
                        title: "Hit Down and Through (3:56)",
                        summary:
                            "Fix early extension to blast the ball into outer space.",
                    },
                    {
                        title: "Trail Knee Back / Bowler Drill (2:17)",
                        summary:
                            "Fix-all drill that greatly increases swing speed and tons of contact issues.",
                    },
                    {
                        title: "90% Weight Forward Drill (2:20)",
                        summary:
                            "Fix-all drill that gives you consistent contact with all clubs. Instantly improves your swing motion.",
                    },
                    {
                        title: "Arms Push Away (2:48)",
                        summary:
                            "Get a straight lead arm for more consistent strikes.",
                    },
                    {
                        title: "Shoulders Go Through (3:09)",
                        summary:
                            "Accelerate the club through the ball for more distance and pinpoint accuracy.",
                    },
                    {
                        title: "Swing Under the Stick (2:19)",
                        summary:
                            "Fix over-the-top forever. Train an in-to-out swing path to eliminate steepness with irons and driver.",
                    },
                    {
                        title: "Shoulder Down / Touch the Stick (3:28)",
                        summary:
                            "Train tilt into your swing and you'll hit the center of the clubface with regularity.",
                    },
                    {
                        title: "Glove + Under Stick [SLICE FIX] (2:32)",
                        summary:
                            "Intense over-the-top / slice fixing drill that annihilates steep downswing problems.",
                    },
                    {
                        title: "Fix an Open Clubface (2:49)",
                        summary:
                            "Trains you to keep the clubface square the throughout the swing. Includes fixes for takeaway, backswing, and downswing.",
                    },
                    {
                        title: "Quiet Hands / Clasp Hands (1:24)",
                        summary:
                            "Train quiet hands and square the clubface for straighter shots. Fixes an open or closed clubface.",
                    },
                ],
            },
        ],
    },
    {
        title: "High-Detail Training: Master the Swing Inside-Out",
        description:
            "In-depth lessons broken into steps. Understand setup, swing, speed, and drills in full detail.",
        subgroups: [
            {
                title: "Step 1: Simple Setup Adjustments",
                lessons: [
                    {
                        title: "Your Setup is Your Swing (5:21)",
                        summary:
                            "Why 95% of the swing is built in your setup — and how setup affects your swing.",
                    },
                    {
                        title: "Set Your Grip (6:15)",
                        summary:
                            "The exact grip that controls the face and adds power.",
                    },
                    {
                        title: "Set Your Weight (4:56)",
                        summary:
                            "Set weight position for consistent contact and effortless motion.",
                    },
                    {
                        title: "Set Your Arms (11:06)",
                        summary:
                            "Build 'The Unit' for a connected swing that drives the entire body.",
                    },
                    {
                        title: "Set Your Posture (10:51)",
                        summary:
                            "Set the correct posture. Includes posture drills for balance, rotation, and clean strikes.",
                    },
                    {
                        title: "Putting It All Together (4:01)",
                        summary:
                            "Build your own pre-shot routine using everything you've learned.",
                    },
                ],
            },
            {
                title: "Step 2: The Simple Swing",
                lessons: [
                    {
                        title: "Train Your Shoulders (9:01)",
                        summary:
                            "Learn the 2 shoulder moves that power the swing.",
                    },
                    {
                        title: "Keep the Trail Arm Tucked In (7:23)",
                        summary:
                            "Shallow the club and swing on plane automatically.",
                    },
                    {
                        title: "Keep Your Weight Forward (3:24)",
                        summary:
                            "Lock in stable contact by staying centered through the swing.",
                    },
                ],
            },
            {
                title: "Step 3: Speed & Distance",
                lessons: [
                    {
                        title: "Gain Tremendous Distance - The 2 Moves You Need! (9:51)",
                        summary:
                            "Add 2 superpower moves to get a 'fully loaded' pro backswing without stressing your body.",
                    },
                    {
                        title: "The Hitting Action (11:14)",
                        summary:
                            "How the trail arm extends through impact to maximize force.",
                    },
                ],
            },
            {
                title: "The Drill – Do This for 30 Days",
                lessons: [
                    {
                        title: "Do the Drill (7:58)",
                        summary:
                            "Perform the full body motion without a club to groove it in. Watch your 'bad swing moves' vanish with each consecutive rep.",
                    },
                    {
                        title: "Do the Drill With a Club (6:14)",
                        summary:
                            "Add a club and practice this drill and gain valuable feels and insights into your own swing.",
                    },
                ],
            },
            {
                title: "Indoor Bonus Content",
                lessons: [
                    {
                        title: "3 KEYS for a Powerful Swing (7:44)",
                        summary:
                            "Unlock a big, fully loaded backswing — no strength or flexibility training required.",
                    },
                    {
                        title: "Backswing Checkpoints + Fixes (8:29)",
                        summary:
                            "Diagnose and fix backswing problems with powerful drills and swing feels.",
                    },
                    {
                        title: "Downswing Checkpoints + Fixes (7:54)",
                        summary:
                            "Diagnose and fix downswing problems with powerful drills and swing feels.",
                    },
                ],
            },
        ],
    },
]

export default function CourseAccordion() {
    const [openSection, setOpenSection] = React.useState<number | null>(null)
    const [openSubgroup, setOpenSubgroup] = React.useState<{
        [key: number]: number | null
    }>({})
    const [openLesson, setOpenLesson] = React.useState<{
        [key: string]: number | null
    }>({})

    const toggleLesson = (
        sectionIndex: number,
        subgroupIndex: number,
        lessonIndex: number
    ) => {
        const key = `${sectionIndex}-${subgroupIndex}`
        setOpenLesson((prev) => ({
            ...prev,
            [key]: prev[key] === lessonIndex ? null : lessonIndex,
        }))
    }

    return (
        <div style={{ maxWidth: 800, margin: "auto", fontFamily: "system-ui" }}>
            {courseSections.map((section, sectionIndex) => (
                <div key={sectionIndex} style={{ marginBottom: "2rem" }}>
                    <button
                        onClick={() =>
                            setOpenSection(
                                openSection === sectionIndex
                                    ? null
                                    : sectionIndex
                            )
                        }
                        style={{
                            width: "100%",
                            padding: "1rem",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            background: "#f9f9f9",
                            cursor: "pointer",
                        }}
                    >
                        {section.title}
                    </button>

                    {openSection === sectionIndex && (
                        <div
                            style={{
                                padding: "1rem",
                                background: "#fff",
                                border: "1px solid #eee",
                                borderTop: "none",
                            }}
                        >
                            <p style={{ marginBottom: "1rem" }}>
                                {section.description}
                            </p>

                            {section.subgroups.map((group, subgroupIndex) => (
                                <div
                                    key={subgroupIndex}
                                    style={{ marginBottom: "1rem" }}
                                >
                                    <button
                                        onClick={() =>
                                            setOpenSubgroup((prev) => ({
                                                ...prev,
                                                [sectionIndex]:
                                                    prev[sectionIndex] ===
                                                    subgroupIndex
                                                        ? null
                                                        : subgroupIndex,
                                            }))
                                        }
                                        style={{
                                            width: "100%",
                                            padding: "0.75rem",
                                            fontSize: "1.05rem",
                                            fontWeight: "600",
                                            textAlign: "left",
                                            border: "1px solid #ddd",
                                            borderRadius: "6px",
                                            background: "#f0f0f0",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {group.title}
                                    </button>

                                    {openSubgroup[sectionIndex] ===
                                        subgroupIndex && (
                                        <div style={{ paddingTop: "0.5rem" }}>
                                            {group.lessons.map(
                                                (lesson, lessonIndex) => {
                                                    const key = `${sectionIndex}-${subgroupIndex}`
                                                    return (
                                                        <div
                                                            key={lessonIndex}
                                                            style={{
                                                                marginBottom:
                                                                    "0.5rem",
                                                            }}
                                                        >
                                                            <button
                                                                onClick={() =>
                                                                    toggleLesson(
                                                                        sectionIndex,
                                                                        subgroupIndex,
                                                                        lessonIndex
                                                                    )
                                                                }
                                                                style={{
                                                                    width: "100%",
                                                                    textAlign:
                                                                        "left",
                                                                    background:
                                                                        "#fff",
                                                                    border: "1px solid #ddd",
                                                                    borderRadius:
                                                                        "4px",
                                                                    padding:
                                                                        "0.5rem 0.75rem",
                                                                    fontSize:
                                                                        "1rem",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {lesson.title}
                                                            </button>
                                                            {openLesson[key] ===
                                                                lessonIndex && (
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            "0.5rem 1rem",
                                                                        background:
                                                                            "#fafafa",
                                                                        borderLeft:
                                                                            "3px solid #ccc",
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{
                                                                            margin: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            lesson.summary
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}
